import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Breadcrumbs } from "../../../modules/layout/Breadcrumbs";
import KeyForm from "../../../modules/forms/KeyForm";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { PERMISSIONS } from "../../../modules/auth/auth";
import useKeys from "../../../modules/api/hooks/useKeys";
import { useUpdateKeys } from "../../../modules/api/hooks/useUpdateKeys";

export interface IUpdateKeyRequest {
  id: string;
  requestedAt: string;
}

export function RequestKeysPage() {
  const { mutate: requestKeys } = useUpdateKeys({
    onSuccess: () => {
      toast.dismiss();
      toast.success("Keys requested successfully!");
      navigate("/dashboard/keys/");
    },
    onError: (error: { response: { data: string } }) => {
      setError(error.response.data);
      toast.dismiss();
      toast.error(error.response.data.trim().slice(0, 80));
    },
  });
  const { data: keys, isLoading } = useKeys(
    "/keys?isRequested=false&page=1&pageSize=100"
  );
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const { getPermission } = useKindeAuth();

  useEffect(() => {
    if (!(getPermission && getPermission(PERMISSIONS.UPDATE_KEY)?.isGranted)) {
      navigate("/dashboard/keys");
    }
  }, [getPermission, navigate]);

  const handleSubmit = (data: string[]) => {
    const selectedKeyObjects =
      keys?.data
        ?.filter(
          (key) => data.includes(key.frameNumber) && key.id !== undefined
        )
        .map((key) => ({
          id: key.id as string,
          requestedAt: new Date().toISOString(),
        })) || [];
    toast.loading("Requesting keys...");
    requestKeys(selectedKeyObjects);
  };

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <Breadcrumbs />
      <div className="flex items-center justify-between">
        <p className="text-2xl tracking-wide font-medium">Request keys</p>
      </div>
      {isLoading ? (
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">
            Select keys to request
          </label>
          <div className="flex flex-col bg-gray-50 border border-gray-300 text-gray-900 p-4 rounded-lg w-full">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={false}
                disabled
                className="className accent-customBlue my-2 w-4 h-4 block mr-2"
              />
              Request all
            </label>
            <hr className="border-gray-300 my-1" />
            {new Array(5).fill(0).map((_, i) => (
              <div key={i}>
                <div className="my-2 bg-gray-200 p-4 rounded-lg w-full animate-pulse"></div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <KeyForm
          allKeys={keys?.data ?? []}
          editMode
          buttonPlaceholder="Request keys"
          onSubmit={(data) => handleSubmit(data)}
        />
      )}

      {error && (
        <div className="text-red-500 mt-4 whitespace-pre-wrap">{error}</div>
      )}
    </div>
  );
}
