import {
  DocumentTextIcon,
  HomeIcon,
  KeyIcon,
  MagnifyingGlassIcon,
  UserGroupIcon,
  Bars3Icon,
  ArrowLeftStartOnRectangleIcon,
  UserIcon,
  WindowIcon,
  QrCodeIcon,
  BeakerIcon,
  MapPinIcon,
} from "@heroicons/react/24/solid";
import { Fragment, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { SearchModal } from "../components/SearchModal";
import BarcodeScanner from "../components/BarcodeScanner";
import { PERMISSIONS } from "../auth/auth";

export default function Navbar2() {
  const { getPermission, getBooleanFlag } = useKindeAuth();
  //const [isSupportTorch, isOpen, onTorchSwitch] = useTorch();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  const BikeIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      className="lucide lucide-bike"
    >
      <circle cx="18.5" cy="17.5" r="3.5" />
      <circle cx="5.5" cy="17.5" r="3.5" />
      <circle cx="15" cy="5" r="1" />
      <path d="M12 17.5V14l-3-3 4-3 2 3h2" />
    </svg>
  );

  const menuItems = [
    { title: "Dashboard", to: "statistics", icon: HomeIcon, show: true },
    {
      title: "Bikes",
      to: "bikes",
      icon: BikeIcon,
      show: true,
    },
    {
      title: "Reports",
      to: "reports",
      icon: DocumentTextIcon,
      show: true,
    },
    {
      title: "Keys",
      to: "keys",
      icon: KeyIcon,
      show: true,
    },
    {
      title: "Map", // For future use, map is not implemented yet
      to: "map", // Show map markers for all active missing bikes last known location
      icon: MapPinIcon,
      show: false,
    },
    {
      title: "Users",
      to: "users",
      icon: UserGroupIcon,
      permission: PERMISSIONS.READ_USERS,
      show: true,
    },
    {
      title: "Logs",
      to: "logs",
      icon: WindowIcon,
      permission: PERMISSIONS.READ_LOGS,
      show: true,
    },
    {
      title: "Submissions",
      to: "submissions",
      icon: BeakerIcon,
      permission: PERMISSIONS.READ_SUBMISSIONS,
      show: true,
    },
    {
      title: "Profile",
      to: "profile",
      icon: UserIcon,
      show: true,
    },
  ];

  return (
    <Fragment>
      <SearchModal
        isOpen={isSearchOpen}
        onClose={() => setIsSearchOpen(false)}
        onOpen={() => setIsSearchOpen(true)}
      />
      {isScannerOpen && (
        <BarcodeScanner
          isOpen={isScannerOpen}
          onClose={() => setIsScannerOpen(false)}
        />
      )}
      <div className="sm:hidden fixed top-5 left-3.5 z-40">
        <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="p-2 text-white bg-customBlue rounded-lg"
        >
          <Bars3Icon className="w-6 h-6" />
        </button>
      </div>
      {/* Sidebar */}
      <aside
        id="default-sidebar"
        ref={sidebarRef}
        className={`fixed top-0 left-0 z-40 h-screen bg-customBlue transition-transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } sm:translate-x-0`}
      >
        <div className="flex flex-col justify-between h-full overflow-y-auto py-5 px-3">
          <div>
            <img
              src="/swapfiets-logo.png"
              alt="swapfiets"
              width="40"
              height="40"
              className="mx-auto mb-2 z-50 rounded-lg"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            />
            <ul className="space-y-4">
              <li>
                <button
                  onClick={() => setIsSearchOpen(true)}
                  className="flex items-center justify-center p-2.5 w-full text-base font-normal text-white rounded-lg transition group hover:bg-customDarkBlue"
                >
                  <MagnifyingGlassIcon className="w-6 h-6" />
                </button>
              </li>
              {getBooleanFlag("qr_scanner", false) ? (
                <li>
                  <button
                    onClick={() => {
                      setIsScannerOpen(!isScannerOpen);
                      setIsSidebarOpen(!isSidebarOpen);
                    }}
                    className="flex items-center justify-center p-2.5 w-full text-base font-normal text-white rounded-lg transition group hover:bg-customDarkBlue"
                  >
                    <QrCodeIcon className="w-6 h-6" />
                  </button>
                </li>
              ) : null}
              {/* Map through dynamic menu items */}
              {menuItems
                .filter((l) => l.show)
                .map((item, i) =>
                  !item.permission ||
                  (item.permission &&
                    getPermission &&
                    getPermission(item.permission)?.isGranted) ? (
                    <li key={item.title}>
                      <NavLink
                        key={item.to + i}
                        to={`/dashboard/${item.to}`}
                        onClick={() => setIsSidebarOpen(false)}
                        title={item.title}
                        className={({ isActive }) =>
                          `flex items-center justify-center p-2.5 w-full text-base font-normal text-white rounded-lg transition group hover:bg-customDarkBlue ${
                            isActive ? "bg-customDarkBlue" : ""
                          }`
                        }
                      >
                        <item.icon className="w-6 h-6" />
                      </NavLink>
                    </li>
                  ) : null
                )}
            </ul>
          </div>
          <div>
            <NavLink
              key={"logout"}
              to={"/dashboard/logout"}
              title={"Logout"}
              className={
                "flex items-center justify-center p-2.5 w-full text-base font-normal text-white rounded-lg transition group hover:bg-customDarkBlue"
              }
            >
              <ArrowLeftStartOnRectangleIcon className="w-6 h-6" />
            </NavLink>
            <p className="text-white italic text-xs">v. 1.3.4</p>
          </div>
        </div>
      </aside>
    </Fragment>
  );
}
