import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { EyeIcon } from "@heroicons/react/24/solid";
import toast from "react-hot-toast";
import useFetchData from "../../../modules/api/hooks/useFetchData";
import Button from "../../../modules/components/Button";
import { Breadcrumbs } from "../../../modules/layout/Breadcrumbs";
import { IBike, isEBike } from "../../../modules/api/interfaces/bike";
import BikeForm from "../../../modules/forms/BikeForm";
import { IBikeForm } from "./bikes.new";
import { useUpdateBike } from "../../../modules/api/hooks/bikes/useUpdateBike";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { PERMISSIONS } from "../../../modules/auth/auth";
import LoadingSpinner from "../../../modules/components/LoadingSpinner";

export function EditBikePage() {
  const [error, setError] = useState("");
  const { mutate: updateBike } = useUpdateBike({
    onSuccess: () => {
      toast.dismiss();
      toast.success("Bike updated successfully!");
      navigate("/dashboard/bikes/" + id);
    },
    onError: (error: { response: { data: string } }) => {
      setError(error.response.data);
      toast.dismiss();
      toast.error(error.response.data.trim().slice(0, 80));
    },
  });
  const { id } = useParams();
  const { data: bikes, isLoading } = useFetchData<IBike>(`/bikes/${id}`);
  const navigate = useNavigate();
  const { getPermission } = useKindeAuth();

  useEffect(() => {
    if (!(getPermission && getPermission(PERMISSIONS.UPDATE_BIKE)?.isGranted)) {
      navigate("/dashboard/bikes");
    }
  }, [getPermission, navigate]);

  if (isLoading) return <LoadingSpinner text="Loading bike..." />;

  const bike = bikes;

  if (!bike || !isEBike(bike.bikeType)) {
    return <Navigate to="/dashboard/bikes" />;
  }

  const handleSubmit = (data: IBikeForm) => {
    toast.loading("Updating bike...");
    updateBike(data);
  };

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <Breadcrumbs />
      <div className="flex items-center justify-between">
        <p className="text-2xl tracking-wide font-medium">Edit bike</p>
        <Button
          variant="icon"
          icon={EyeIcon}
          onClick={() => navigate("/dashboard/bikes/" + id)}
        />
      </div>
      <BikeForm
        bikeData={bike}
        editMode
        readOnly
        buttonPlaceholder="Update bike"
        onSubmit={(data) => {
          handleSubmit(data);
        }}
      />

      {error && (
        <div className="text-red-500 mt-4 whitespace-pre-wrap">{error}</div>
      )}
    </div>
  );
}
