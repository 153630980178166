import { useState, useEffect, useRef } from "react";
import { useController, Control } from "react-hook-form";
import { IBike } from "../api/interfaces/bike";
import clsx from "clsx";
import useBikes from "../api/hooks/bikes/useBikes";
import { useDebounce } from "../api/hooks/useDebounce";

interface BikeFrameNumberInputProps {
  control: Control<any>;
  name: string;
  label?: string;
  disabled?: boolean;
  className?: string;
  handleClick?: () => void;
}

export default function BikeFrameNumberInput({
  control,
  name,
  label = "Frame number",
  disabled = false,
  className,
  handleClick,
}: BikeFrameNumberInputProps) {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control });
  const debouncedSearchTerm = useDebounce(value, 700);

  const { data: bikes, isLoading } = useBikes(
    `frameNumber=${debouncedSearchTerm}&page=1&pageSize=10`
  );
  const [isListOpen, setIsListOpen] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const validateInput = (frameNumber: string) => {
    const isValidFrameNumber =
      frameNumber.length > 0 &&
      bikes?.data.some(
        (bike: IBike) =>
          bike.frameNumber.toLowerCase() === frameNumber.toLowerCase()
      );
    setIsValid(!!isValidFrameNumber);
  };

  useEffect(() => {
    validateInput(debouncedSearchTerm || "");
  }, [debouncedSearchTerm, bikes]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const frameNumber = e.target.value;
    onChange(frameNumber); // Set the value in react-hook-form
    setIsListOpen(true);
    validateInput(frameNumber);
  };

  const handleItemClick = (frameNumber: string) => {
    onChange(frameNumber); // Set the selected frame number
    setIsListOpen(false);
    validateInput(frameNumber);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsListOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full" ref={wrapperRef}>
      <label
        htmlFor={name}
        className="block mb-2 text-sm font-medium text-gray-900"
      >
        {label}
      </label>
      <input
        type="text"
        name={name}
        id={name}
        readOnly={disabled}
        className={clsx(
          className,
          "disabled:opacity-100 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline outline-2 focus:outline-customBlue block w-full p-2.5",
          disabled
            ? ""
            : isValid
            ? "border-green-500 border-2"
            : "border-red-500 border-2",
          error ? "border-red-500" : ""
        )}
        placeholder="Type frame number of the bike"
        value={value || ""}
        onChange={handleInputChange}
        onClick={handleClick}
      />
      {error && <p className="text-red-500 text-xs mt-1">{error.message}</p>}

      {isListOpen && (
        <ul className="absolute bg-white border border-gray-300 mt-1 rounded-lg shadow-md max-h-60 overflow-y-auto w-full z-10">
          {isLoading ? (
            <li className="p-2 text-gray-500">Searching...</li>
          ) : (bikes?.totalCount ?? 0) > 0 ? (
            bikes?.data.map((bike, index) => (
              <li
                key={index}
                className="p-2 hover:bg-customBlue hover:text-white cursor-pointer"
                onClick={() => handleItemClick(bike.frameNumber)}
              >
                {bike.frameNumber}
              </li>
            ))
          ) : (
            <li className="p-2 text-gray-500">No results found</li>
          )}
        </ul>
      )}
    </div>
  );
}
