import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";
import { IBike } from "../../interfaces/bike";
import { IPaginationModel } from "../../interfaces/paginationModel";
import { useToken } from "../misc/useToken";

export const useBikes = (queryFilters?: string) => {
  const accessToken = useToken();

  const fetchData = async (): Promise<IPaginationModel<IBike>> => {
    if (!accessToken) {
      throw new Error("No access token available");
    }

    try {
      const { data } = await axios.get<IPaginationModel<IBike>>(
        `${process.env.REACT_APP_API_URL}/bikes?${queryFilters}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      toast.error(error.response?.data || `Failed to fetch bikes`);
      throw error;
    }
  };

  const { isLoading, data, error } = useQuery({
    queryKey: ["bikes", { queryFilters }],
    queryFn: fetchData,
    select: (data) => data,
  });

  return { isLoading, data, error };
};

export default useBikes;
