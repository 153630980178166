import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const useFetchData = <T,>(endpoint: string) => {
  const fetchData = async (): Promise<T> => {
    const { data } = await axios.get<T>(
      `${process.env.REACT_APP_API_URL}${endpoint}`
    );
    return data;
  };

  const { isLoading, data, error } = useQuery({
    queryKey: [endpoint],
    queryFn: fetchData,
    select: (data) => data,
  });

  return { isLoading, data, error };
};

export default useFetchData;
