import { KindeProvider } from "@kinde-oss/kinde-auth-react";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { ProtectedRoute } from "./modules/auth/ProtectedRoute";
import { Layout } from "./modules/layout/layout";
import {
  BikesPage,
  EditBikePage,
  EditReportPage,
  Error404Page,
  KeysPage,
  LogOutPage,
  LogsPage,
  MainPage,
  NewBikePage,
  NewReportPage,
  NewSubmissionPage,
  ProfilePage,
  ReportsPage,
  RequestKeysPage,
  ShowBikePage,
  ShowKeyPage,
  ShowLogsPage,
  ShowReportPage,
  ShowSubmissionPage,
  ShowUserPage,
  SubmissionsPage,
  UsersPage,
} from "./pages/dashboard";
import { NotFound, WelcomePage } from "./pages";
import ErrorPage from "./pages/ErrorPage";
import { AxiosInterceptor } from "./modules/api/AxiosIntercepor";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 1, // 1 minute(s)
      gcTime: 1000 * 60 * 60 * 24, // 24 hour(s)
      retry: 6,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

function App() {
  const callback = useMemo(
    () => `${window.location.origin}/dashboard/statistics`,
    []
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <KindeProvider
        audience={process.env.REACT_APP_AUDIENCE_DOMAIN}
        clientId={process.env.REACT_APP_CLIENT_ID}
        domain={process.env.REACT_APP_KINDE_DOMAIN || ""}
        redirectUri={callback}
        logoutUri={window.location.origin}
      >
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{ persister }}
        >
          <AxiosInterceptor>
            <Toaster />
            <BrowserRouter>
              <Routes>
                <Route index element={<Navigate to="sign-in" />} />
                <Route path={"sign-in"} element={<WelcomePage />} />
                {/*<Route path={"handleCallback"} element={<HandleCallbackPage />} />*/}

                <Route
                  path="dashboard"
                  element={
                    <ProtectedRoute>
                      <Layout />
                    </ProtectedRoute>
                  }
                >
                  <Route index element={<Navigate to="statistics" />} />
                  <Route path="statistics" element={<MainPage />} />

                  <Route path="bikes" element={<BikesPage />} />
                  <Route path="bikes/new" element={<NewBikePage />} />
                  <Route path="bikes/:id" element={<ShowBikePage />} />
                  <Route path="bikes/edit/:id" element={<EditBikePage />} />

                  <Route path="reports" element={<ReportsPage />} />
                  <Route path="reports/new" element={<NewReportPage />} />
                  <Route path="reports/:id" element={<ShowReportPage />} />
                  <Route path="reports/edit/:id" element={<EditReportPage />} />

                  <Route path="users" element={<UsersPage />} />
                  <Route path="users/:id" element={<ShowUserPage />} />

                  <Route path="keys" element={<KeysPage />} />
                  <Route path="keys/request" element={<RequestKeysPage />} />
                  <Route path="keys/:id" element={<ShowKeyPage />} />

                  <Route path="logout" element={<LogOutPage />} />

                  <Route path="profile" element={<ProfilePage />} />

                  <Route path="submissions" element={<SubmissionsPage />} />
                  <Route
                    path="submissions/new"
                    element={<NewSubmissionPage />}
                  />
                  <Route
                    path="submissions/:id"
                    element={<ShowSubmissionPage />}
                  />

                  <Route path="logs" element={<LogsPage />} />
                  <Route path="logs/:id" element={<ShowLogsPage />} />

                  <Route path="*" element={<Error404Page />} />
                </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>
          </AxiosInterceptor>
        </PersistQueryClientProvider>
      </KindeProvider>
    </ErrorBoundary>
  );
}

export default App;
