import { IEntity } from "./entity";

export interface ISubmission extends IEntity {
  fullName: string;
  email: string;
  title: string;
  type: SubmissionType;
  description: string;
  isOpen: boolean;
}

export enum SubmissionType {
  Select = -1,
  Feature = 1,
  Bug = 2,
}
