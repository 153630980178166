import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { EyeIcon } from "@heroicons/react/24/solid";
import toast from "react-hot-toast";
import { useUpdateReport } from "../../../modules/api/hooks/reports/useUpdateReport";
import useFetchData from "../../../modules/api/hooks/useFetchData";
import { IReport } from "../../../modules/api/interfaces/report";
import Button from "../../../modules/components/Button";
import ReportForm from "../../../modules/forms/ReportForm";
import { Breadcrumbs } from "../../../modules/layout/Breadcrumbs";
import LoadingSpinner from "../../../modules/components/LoadingSpinner";
import { IReportForm } from "./reports.new";

export function EditReportPage() {
  const { mutate: updateReport } = useUpdateReport({
    onSuccess: () => {
      toast.dismiss();
      toast.success("Report updated successfully!");
      navigate("/dashboard/reports/" + id);
    },
    onError: (error: { response: { data: string } }) => {
      setError(error.response.data);
      toast.dismiss();
      toast.error(error.response.data.trim().slice(0, 80));
    },
  });
  const { id } = useParams();
  const { data: reports, isLoading } = useFetchData<IReport>(`/reports/${id}`);
  const navigate = useNavigate();
  const report = reports;
  const [error, setError] = useState("");

  if (isLoading) return <LoadingSpinner text="Loading report..." />;

  if (!report) {
    <Navigate to="/dashboard/reports" />;
  }

  const handleSubmit = (data: IReportForm) => {
    toast.loading("Updating report...");
    updateReport(data);
  };

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <Breadcrumbs />
      <div className="flex items-center justify-between">
        <p className="text-2xl tracking-wide font-medium">Edit report</p>
        <Button
          variant="icon"
          icon={EyeIcon}
          onClick={() => navigate("/dashboard/reports/" + id)}
        />
      </div>
      <ReportForm
        reportData={report}
        buttonPlaceholder="Update report"
        onSubmit={(data) => handleSubmit(data)}
      />

      {error && (
        <div className="text-red-500 mt-4 whitespace-pre-wrap">{error}</div>
      )}
    </div>
  );
}
