import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { IReportForm } from "../../../../pages/dashboard";
import { IErrorResponse } from "../../interfaces/error";

interface IUseUpdateReportProps {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

export const useUpdateReport = (props?: IUseUpdateReportProps) => {
  const { getUser } = useKindeAuth();
  const queryClient = useQueryClient();

  const updateReportWithJournalNumber = async (report: IReportForm) => {
    const user = getUser?.();

    return await axios.put(process.env.REACT_APP_API_URL + "/reports", {
      id: report.id,
      journalNumber: report.journalNumber,
      date: report.date,
      isActive: report.isActive,
      priority: report.priority,
      description: report.description,
      bikeFrameNumber: report.bikeFrameNumber,
      updatedBy: user ? `${user.given_name} ${user.family_name}` : "Unknown",
    });
  };

  return useMutation({
    mutationKey: ["update", "reports"],
    mutationFn: (report: IReportForm) => updateReportWithJournalNumber(report),
    onError: (error: IErrorResponse) => {
      props?.onError?.(error);
    },
    onSuccess: async (data, report) => {
      await queryClient.invalidateQueries({
        queryKey: ["reports"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["bikes"],
      });
      await queryClient.invalidateQueries({
        queryKey: [`/reports/${report.id}`],
      });
      props?.onSuccess?.();
    },
  });
};
