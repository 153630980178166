import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";
import { IPaginationModel } from "../../interfaces/paginationModel";
import { useToken } from "../misc/useToken";
import { IReport } from "../../interfaces/report";

export const useReports = (queryFilters?: string) => {
  const accessToken = useToken();

  const fetchData = async (): Promise<IPaginationModel<IReport>> => {
    if (!accessToken) {
      throw new Error("No access token available");
    }

    try {
      const { data } = await axios.get<IPaginationModel<IReport>>(
        `${process.env.REACT_APP_API_URL}/reports?${queryFilters}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return data;
    } catch (error: any) {
      toast.error(error.response?.data?.message || `Failed to fetch reports`);
      throw error;
    }
  };

  const { isLoading, data, error } = useQuery({
    queryKey: ["reports", { queryFilters }],
    queryFn: fetchData,
    select: (data) => data,
  });

  return { isLoading, data, error };
};

export default useReports;
