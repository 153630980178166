import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useToken } from "../misc/useToken";
import { IBikeForm } from "../../../../pages/dashboard";

interface IUseUpdateBikeProps {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

export const useUpdateBike = (props?: IUseUpdateBikeProps) => {
  const queryClient = useQueryClient();
  const accessToken = useToken();

  const updateBikeWithFrameNumber = async (bike: IBikeForm) => {
    if (!accessToken) {
      throw new Error("No access token available");
    }

    return await axios.put(
      process.env.REACT_APP_API_URL + "/bikes",
      {
        id: bike.id,
        frameNumber: bike.frameNumber,
        trackerId: bike.trackerId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  return useMutation({
    mutationKey: ["update", "bikes"],
    mutationFn: (bike: IBikeForm) => updateBikeWithFrameNumber(bike),
    onError: (error: Error) => {
      console.error(error);
      props?.onError?.(error);
    },
    onSuccess: async (data, bike) => {
      await queryClient.invalidateQueries({
        queryKey: ["reports"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["bikes"],
      });
      await queryClient.invalidateQueries({
        queryKey: [`/bikes/${bike.id}`],
      });
      props?.onSuccess?.();
    },
  });
};
