import { useNavigate } from "react-router-dom";
import { Fragment, useEffect } from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { IUser } from "../../../modules/api/interfaces/user";
import Table from "../../../modules/components/table/Table";
import MainLayout from "../../../modules/components/MainLayout";
import { PERMISSIONS } from "../../../modules/auth/auth";
import SearchComponent, {
  FilterConfig,
} from "../../../modules/components/search/SearchComponent";
import { useSearchHandler } from "../../../modules/api/hooks/useSearchHandler";
import useUsers from "../../../modules/api/hooks/useUsers";

const usersFilters: FilterConfig[] = [
  {
    key: "isSuspended",
    label: "Suspended",
    type: "select",
    options: [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
  },
];

const columns = [
  {
    label: "Name",
    accessor: (row: IUser) => row.name,
  },
  {
    label: "Email",
    accessor: (row: IUser) => row.email,
    classNameHeader: "hidden xl:table-cell",
    classNameCell: "hidden xl:table-cell",
  },
  {
    label: "Suspended?",
    accessor: (row: IUser) => row.isSuspended === true,
    render: (suspended: boolean) => (
      <span
        className={`px-2 py-1 rounded-full text-white ${
          suspended ? "bg-green-500" : "bg-red-500"
        }`}
      >
        {suspended ? "Yes" : "No"}
      </span>
    ),
  },
];

export function UsersPage() {
  const {
    handleSearch,
    handlePageChange,
    initialFiltersFromParams,
    searchParams,
  } = useSearchHandler(usersFilters, "usersSearchParams", "search");
  const { data: users, isLoading } = useUsers(searchParams.toString());
  const navigate = useNavigate();
  const { getPermission } = useKindeAuth();

  useEffect(() => {
    if (!(getPermission && getPermission(PERMISSIONS.READ_USERS)?.isGranted)) {
      navigate("/dashboard/statistics");
    }
  }, [getPermission, navigate]);

  return (
    <MainLayout
      title="Users"
      searchHeader={
        <SearchComponent
          placeholder="Search name or email..."
          searchType="text"
          initialFilters={usersFilters}
          appliedFilters={initialFiltersFromParams}
          onSearch={handleSearch}
        />
      }
      body={
        <Fragment>
          <Table
            data={users?.data || []}
            columns={columns}
            tableActions={{
              deleteFn: (item) => console.log("Deleting", item),
              deleteMessageFn: (item) =>
                `Are you sure you want to delete ${item.name}?`,
            }}
            page={users?.page || 1}
            pageSize={users?.pageSize || 10}
            totalCount={users?.totalCount || 0}
            onPageChange={(page) => handlePageChange(page)}
            idAccessor={(row) => row.id}
            isLoading={isLoading}
            emptyMessage="No users found"
          />
        </Fragment>
      }
    />
  );
}
