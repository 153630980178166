import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Navigate } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import LoadingSpinner from "../modules/components/LoadingSpinner";
import Button from "../modules/components/Button";

export function WelcomePage() {
  const { login, isAuthenticated, isLoading } = useKindeAuth();

  if (isLoading) return <LoadingSpinner text="Loading..." />;
  if (isAuthenticated) return <Navigate to="/dashboard/statistics" />;

  const handleRequestAccess = () => {
    window.open("https://authswap.kyed3.dk/knock-knock", "_blank");
  };

  return (
    <Fragment>
      <img
        src="/swap-store-overlay.jpg"
        alt="swapfiets background"
        className="fixed inset-0 h-screen w-screen object-cover z-0"
      />

      <div className="fixed inset-0 flex items-center justify-center z-10">
        <div className="relative max-w-sm w-full items-center justify-center bg-white border border-gray-100 px-16 sm:px-20 py-12 rounded-lg shadow-md text-center m-4 z-10">
          <h1 className="text-2xl inline-block font-thin items-center mb-6">
            This is
            <span className="font-semibold text-gray-800"> SwapClaim</span>
          </h1>
          <p className="text-gray-500 text-xs mb-3">
            Please log in to start using the system
          </p>
          <Button
            onClick={() => login()}
            title="Login"
            variant="primary"
            className="w-full"
          />
          <Button
            title="Request Access"
            variant="secondary"
            className="w-full mt-3"
            onClick={handleRequestAccess}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default WelcomePage;
