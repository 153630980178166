import { Link, useNavigate } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Fragment, useEffect } from "react";
import {
  ISubmission,
  SubmissionType,
} from "../../../modules/api/interfaces/submission";
import Button from "../../../modules/components/Button";
import Table from "../../../modules/components/table/Table";
import MainLayout from "../../../modules/components/MainLayout";
import { PERMISSIONS } from "../../../modules/auth/auth";
import SearchComponent, {
  FilterConfig,
} from "../../../modules/components/search/SearchComponent";
import { useSearchHandler } from "../../../modules/api/hooks/useSearchHandler";
import useSubmissions from "../../../modules/api/hooks/submissions/useSubmissions";

const submissionsFilters: FilterConfig[] = [
  {
    key: "type",
    label: "Type",
    type: "select",
    options: [
      { label: "Feature", value: "1" },
      { label: "Bug", value: "2" },
    ],
  },
  {
    key: "isOpen",
    label: "Open",
    type: "select",
    options: [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
  },
];

const columns = [
  {
    label: "Type",
    accessor: (row: ISubmission) => SubmissionType[row.type],
    render: (type: SubmissionType) => (
      <span
        className={`px-2 w-16 text-center rounded inline-block ${
          Number(SubmissionType[type]) === 1
            ? "bg-cyan-200 text-cyan-800"
            : "bg-indigo-200 text-indigo-800"
        }`}
      >
        {type}
      </span>
    ),
    classNameCell: "text-center sm:text-left",
    classNameHeader: "text-center sm:text-left",
  },
  {
    label: "Title",
    accessor: (row: ISubmission) => row.title,
    render: (title: string) => {
      return title.length > 15 ? title.slice(0, 15) + "..." : title;
    },
  },
  {
    label: "Summary",
    accessor: (row: ISubmission) => row.description,
    render: (description: string) => {
      return description.slice(0, 10) + "...";
    },
    classNameHeader: "hidden xl:table-cell",
    classNameCell: "hidden xl:table-cell",
  },
  {
    label: "Open?",
    accessor: (row: ISubmission) => row.isOpen === true,
    render: (isOpen: boolean) => (
      <span
        className={`px-2 py-1 rounded-full text-white ${
          isOpen ? "bg-green-500" : "bg-red-500"
        }`}
      >
        {isOpen ? "Yes" : "No"}
      </span>
    ),
  },
];

export function SubmissionsPage() {
  const {
    handleSearch,
    handlePageChange,
    initialFiltersFromParams,
    searchParams,
  } = useSearchHandler(submissionsFilters, "submissionsSearchParams", "search");
  const { data: submissions, isLoading } = useSubmissions(
    searchParams.toString()
  );

  const navigate = useNavigate();
  const { getPermission } = useKindeAuth();

  useEffect(() => {
    if (
      !(getPermission && getPermission(PERMISSIONS.READ_SUBMISSIONS)?.isGranted)
    ) {
      navigate("/dashboard/statistics");
    }
  }, [getPermission, navigate]);

  return (
    <MainLayout
      title="Submissions"
      buttons={
        <Fragment>
          <Link to="./new">
            <Button
              title="Create new"
              variant="primary"
              className="w-full sm:w-auto"
            />
          </Link>
        </Fragment>
      }
      searchHeader={
        <SearchComponent
          placeholder="Search..."
          searchType="text"
          initialFilters={submissionsFilters}
          appliedFilters={initialFiltersFromParams}
          onSearch={handleSearch}
        />
      }
      body={
        <Fragment>
          <Table
            data={submissions?.data || []}
            columns={columns}
            tableActions={{
              deleteFn: (item) => console.log("Deleting", item),
              deleteMessageFn: (item) =>
                `Are you sure you want to delete ${item.id}?`,
            }}
            page={submissions?.page || 1}
            pageSize={submissions?.pageSize || 10}
            totalCount={submissions?.totalCount || 0}
            onPageChange={(page) => handlePageChange(page)}
            idAccessor={(row) => row.id}
            isLoading={isLoading}
            emptyMessage="No submissions found"
          />
        </Fragment>
      }
    />
  );
}
