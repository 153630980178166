import clsx from "clsx";
import React from "react";
import { useController, Control } from "react-hook-form";
import { IKey, KeyType } from "../../api/interfaces/key";

interface FormMultiSelectProps {
  name: string;
  control: Control<any>;
  label?: string;
  options: IKey[];
  disabled?: boolean;
  className?: string;
}

const FormMultiSelect: React.FC<FormMultiSelectProps> = ({
  name,
  control,
  label,
  options,
  disabled = false,
  className,
}) => {
  const {
    field: { value = [], onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const handleCheckboxChange = (option: string) => {
    let newValue = Array.isArray(value) ? [...value] : [];
    if (newValue.includes(option)) {
      newValue = newValue.filter((item) => item !== option);
    } else {
      newValue.push(option);
    }
    onChange(newValue);
  };

  return (
    <div>
      {label && (
        <label className="block mb-2 text-sm font-medium text-gray-900">
          {label}
        </label>
      )}

      <div
        className={clsx(
          className,
          "flex flex-col bg-gray-50 border border-gray-300 text-gray-900 p-4 rounded-lg w-full"
        )}
      >
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            checked={options.length > 0 && value.length === options.length}
            onChange={() => {
              if (value.length === options.length) {
                onChange([]);
              } else {
                onChange(options.map((option) => option.frameNumber));
              }
            }}
            disabled={disabled}
            className={clsx(
              className,
              "accent-customBlue my-2 w-4 h-4 block mr-2"
            )}
          />
          Request all
        </label>
        <hr className="border-gray-300 my-1" />
        {options.map((option) => (
          <label key={option.frameNumber} className="inline-flex items-center">
            <input
              type="checkbox"
              value={option.frameNumber}
              checked={
                Array.isArray(value) && value.includes(option.frameNumber)
              }
              onChange={() => handleCheckboxChange(option.frameNumber)}
              disabled={disabled}
              className={clsx(
                className,
                "accent-customBlue my-2 w-4 h-4 block mr-2",
                error && "border-red-500 border-2"
              )}
            />
            {option.frameNumber + " - " + KeyType[option.keyType]}
          </label>
        ))}
      </div>
    </div>
  );
};

export default FormMultiSelect;
