import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "../components/Button";
import { IReport, ReportPriority } from "../api/interfaces/report";
import FormSelect from "../components/form/FormSelect";
import FormInput from "../components/form/FormInput";
import FormTextArea from "../components/form/FormTextArea";
import BikeFrameNumberInput from "../components/BikeFrameNumberInput";
import { useNavigate } from "react-router-dom";
import FormDate from "../components/form/FormDate";

// Validation schema using Yup
const reportSchema = yup.object({
  id: yup.string().optional(),
  journalNumber: yup
    .string()
    .matches(/^[\d-]+$/, "Journal number must contain only numbers and dashes")
    .min(3, "Journal number must be at least 3 characters long")
    .required("Journal number is required"),
  bikeFrameNumber: yup
    .string()
    .matches(/^\d+$/, "Frame number must contain only numbers")
    .min(5, "Frame number must be at least 5 characters long")
    .required("Frame number is required"),
  date: yup
    .string()
    .required("Date is required")
    .typeError("Invalid date format"),
  isActive: yup
    .boolean()
    .required("Active status is required")
    .typeError("Active status must be yes or no"),
  priority: yup
    .number()
    .oneOf(
      Object.values(ReportPriority).filter(
        (value) => typeof value === "number"
      ) as number[],
      "Select a priority"
    )
    .required("Priority is required"),
  description: yup
    .string()
    .min(10, "Description must be at least 10 characters long")
    .required("Description is required"),
  updatedBy: yup.string().required("Updated by is required"),
});

type ReportFormData = Omit<
  IReport,
  "created" | "deleted" | "updated" | "modified"
> & {
  id?: string;
};

interface ReportFormProps {
  reportData?: IReport;
  readOnly?: boolean;
  buttonPlaceholder?: string;
  onSubmit?: (data: ReportFormData) => void;
}

const ReportForm: React.FC<ReportFormProps> = ({
  reportData,
  readOnly = false,
  buttonPlaceholder = "Submit",
  onSubmit = () => {},
}) => {
  const descriptionPlaceholder = `How did it happen?:\n\nAddress:\n\nAdditional information:\n\nCreated by:\n`;
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ReportFormData>({
    defaultValues: reportData || {
      id: "",
      journalNumber: "",
      date: "",
      description: descriptionPlaceholder,
      isActive: true,
      priority: ReportPriority.None,
      bikeFrameNumber: "",
      updatedBy: "Anonymous",
    },
    resolver: yupResolver(reportSchema),
  });

  const priorityOptions = Object.entries(ReportPriority)
    .filter(([key]) => isNaN(Number(key)))
    .map(([key, value]) => ({ label: key, value }));

  return (
    <form>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6 mt-4">
        <FormInput
          name="journalNumber"
          type="text"
          control={control}
          label="Journal number"
          disabled={readOnly}
          placeholder="Journal number"
        />

        <BikeFrameNumberInput
          name="bikeFrameNumber"
          control={control}
          disabled={readOnly}
          className="hover:read-only:text-customBlue cursor-pointer read-only:underline"
          handleClick={() =>
            readOnly &&
            navigate(`/dashboard/bikes/${control._formValues.bikeId}`)
          }
        />

        <FormDate
          name="date"
          type="date"
          control={control}
          label="Date"
          disabled={readOnly}
        />

        <FormSelect
          name="isActive"
          control={control}
          label="Active?"
          disabled={readOnly}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        />

        <FormSelect
          name="priority"
          control={control}
          label="Priority"
          options={priorityOptions}
          disabled={readOnly}
        />

        <div className="col-span-1 sm:col-span-2">
          <FormTextArea
            name="description"
            control={control}
            label="Description"
            placeholder={"Write the report description here."}
            disabled={readOnly}
          />
        </div>
      </div>

      {!readOnly && (
        <Button
          onClick={handleSubmit(onSubmit)}
          title={buttonPlaceholder}
          variant="primary"
          isLoading={isSubmitting}
          disabled={isSubmitting}
          className="mt-4 w-full"
        />
      )}
      <FormInput
        className="sr-only"
        type="text"
        name="bikeId"
        control={control}
        disabled={readOnly}
        placeholder="Bike Id"
      />
      <FormInput
        className="sr-only"
        type="text"
        name="id"
        control={control}
        disabled={readOnly}
        placeholder="Id"
      />
      <FormInput
        className="sr-only"
        type="text"
        name="updatedBy"
        control={control}
        disabled={readOnly}
        placeholder="Updated by"
      />
    </form>
  );
};

export default ReportForm;
