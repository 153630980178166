import { Link } from "react-router-dom";
import {
  BikeColorHexMap,
  BikeType,
  hexToBikeColorMap,
  IBike,
  isEBike,
} from "../../../modules/api/interfaces/bike";
import Table from "../../../modules/components/table/Table";
import Button from "../../../modules/components/Button";
import SearchComponent, {
  FilterConfig,
} from "../../../modules/components/search/SearchComponent";
import toast from "react-hot-toast";
import { useDeleteItem } from "../../../modules/api/hooks/useDeleteItem";
import { Fragment } from "react/jsx-runtime";
import MainLayout from "../../../modules/components/MainLayout";
import { PERMISSIONS } from "../../../modules/auth/auth";
import useBikes from "../../../modules/api/hooks/bikes/useBikes";
import { useSearchHandler } from "../../../modules/api/hooks/useSearchHandler";

const bikeFilters: FilterConfig[] = [
  {
    key: "type",
    label: "Type",
    type: "select",
    options: [
      { label: "Original", value: "0" },
      { label: "Deluxe7", value: "1" },
      { label: "Power1", value: "2" },
      { label: "Power7", value: "3" },
    ],
  },
  {
    key: "color",
    label: "Color",
    type: "select",
    options: Object.entries(BikeColorHexMap)
      .sort(([colorNameA], [colorNameB]) =>
        colorNameA.localeCompare(colorNameB)
      )
      .map(([colorName, hexValue]) => ({
        label: colorName,
        value: hexValue,
      })),
  },
  {
    key: "missing",
    label: "Missing",
    type: "select",
    options: [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
  },
];

const columns = [
  {
    label: "Frame number",
    accessor: (row: IBike) => "SF" + row.frameNumber,
  },
  {
    label: "Color",
    accessor: (row: IBike) => row.color,
    render: (color: string) => (
      <div className="inline ml-2 sm:ml-0">
        <span className="mr-0 sm:mr-2" style={{ color: color }}>
          ⬤
        </span>
        <span className="hidden sm:table-cell">{hexToBikeColorMap[color]}</span>
      </div>
    ),
  },
  {
    label: "Type",
    accessor: (row: IBike) => BikeType[row.bikeType],
    classNameCell: "text-center sm:text-left",
    classNameHeader: "text-center sm:text-left",
  },
  {
    label: "Tracker",
    accessor: (row: IBike) => row.trackerId?.toString() || "N/A",
    classNameCell: "hidden lg:table-cell text-center sm:text-left",
    classNameHeader: "hidden lg:table-cell text-center sm:text-left",
  },
  {
    label: "Missing",
    accessor: (row: IBike) => row.reports?.some((report) => report.isActive),
    render: (active: boolean) => (
      <span
        className={`px-2 py-1 rounded-full text-white ${
          active ? "bg-green-500" : "bg-red-500"
        }`}
      >
        {active ? "Yes" : "No"}
      </span>
    ),
    classNameCell: "text-center sm:text-left",
    classNameHeader: "text-center sm:text-left",
  },
];

export function BikesPage() {
  const {
    handleSearch,
    handlePageChange,
    initialFiltersFromParams,
    searchParams,
  } = useSearchHandler(bikeFilters, "bikesSearchParams", "frameNumber");
  const { data: bikes, isLoading } = useBikes(searchParams.toString());

  const { mutate: deleteBike } = useDeleteItem("/bikes", {
    onSuccess: () => {
      toast.success("Bike deleted successfully!");
    },
    onError: (error: { response: { data: string } }) => {
      toast.error(error.response.data);
    },
  });

  const handleDelete = (bike: IBike) => {
    deleteBike({ id: bike.id || "" });
  };

  return (
    <MainLayout
      title="Bikes"
      buttons={
        <Fragment>
          <Link to="./new">
            <Button
              title="Create bike"
              variant="primary"
              className="w-full sm:w-auto"
            />
          </Link>
        </Fragment>
      }
      searchHeader={
        <SearchComponent
          placeholder="Search frame number..."
          searchType="number"
          initialFilters={bikeFilters}
          appliedFilters={initialFiltersFromParams}
          onSearch={handleSearch}
        />
      }
      body={
        <Fragment>
          <Table
            data={bikes?.data || []}
            columns={columns}
            isLoading={isLoading}
            tableActions={{
              deleteFn: (item) => handleDelete(item),
              deleteMessageFn: (item) =>
                `Are you sure you want to delete bike with frame number - ${item.frameNumber}?`,
              shouldShowEdit: (item: IBike) => isEBike(item.bikeType),
            }}
            page={bikes?.page || 1}
            pageSize={bikes?.pageSize || 10}
            totalCount={bikes?.totalCount || 0}
            onPageChange={(page) => handlePageChange(page)}
            idAccessor={(row) => row.id}
            emptyMessage="No bikes found"
            editPermission={PERMISSIONS.UPDATE_BIKE}
            deletePermission={PERMISSIONS.DELETE_BIKE}
          />
        </Fragment>
      }
    />
  );
}

export default BikesPage;
