import { Navigate, useNavigate, useParams } from "react-router-dom";
import useFetchData from "../../../modules/api/hooks/useFetchData";
import { IUser } from "../../../modules/api/interfaces/user";
import InputField from "../../../modules/components/form/InputField";
import LoadingSpinner from "../../../modules/components/LoadingSpinner";
import { Breadcrumbs } from "../../../modules/layout/Breadcrumbs";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useEffect } from "react";
import { PERMISSIONS } from "../../../modules/auth/auth";

export function ShowUserPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: user, isLoading } = useFetchData<IUser>(`/users/${id}`);
  const { getPermission } = useKindeAuth();

  useEffect(() => {
    if (!(getPermission && getPermission(PERMISSIONS.READ_USERS)?.isGranted)) {
      navigate("/dashboard/statistics");
    }
  }, [getPermission, navigate]);

  if (isLoading) return <LoadingSpinner text="Loading user..." />;

  if (!user) {
    return <Navigate to="/dashboard/users" />;
  }

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <Breadcrumbs />
      <div className="flex items-center justify-between">
        <p className="text-2xl tracking-wide font-medium">User</p>
      </div>
      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 mt-4">
        <InputField
          label="ID"
          name="id"
          type="text"
          value={user?.id || ""}
          readonly
        />
        <InputField
          label="Name"
          name="name"
          type="text"
          value={user?.name}
          readonly
        />
        <InputField
          label="Email"
          name="email"
          type="text"
          value={user?.email}
          readonly
          className="break-all"
        />
        <InputField
          label="Suspended?"
          name="suspended"
          type="text"
          value={user?.isSuspended ? "Yes" : "No"}
          readonly
          className="break-all"
        />
      </div>
    </div>
  );
}
