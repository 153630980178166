import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import axios, { AxiosError, AxiosResponse } from "axios";
import { ReactNode, useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { IErrorResponse } from "./interfaces/error";
import LoadingSpinner from "../components/LoadingSpinner";

export const AxiosInterceptor = ({ children }: { children: ReactNode }) => {
  const { getToken } = useKindeAuth();
  const [accessToken, setAccessToken] = useState<string | null>();
  const [isTokenLoaded, setIsTokenLoaded] = useState(false);

  // Initialize token when component mounts
  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await getToken?.();
        setAccessToken(token || null);
      } finally {
        setIsTokenLoaded(true); // Mark token as loaded to allow rendering
      }
    };

    fetchToken();
  }, [getToken]);

  const requestHandler = useCallback(
    async (config: any) => {
      if (accessToken) {
        config.headers = {
          ...config.headers,
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        };
      }
      return config;
    },
    [accessToken]
  );

  const responseHandler = useCallback((response: AxiosResponse) => {
    return response;
  }, []);

  const errorHandler = useCallback(
    async (error: AxiosError) => {
      const { response } = error;

      if (!response) return;

      const { status } = response;

      // @ts-ignore
      if (status === 401) {
        // @ts-ignore

        try {
          const newToken = await getToken?.();

          if (newToken) {
            setAccessToken(newToken);

            return;
          }
        } catch (refreshError) {
          console.error("Failed to refresh token:", refreshError);
        }
      }

      const errorData = response.data as IErrorResponse;
      // Dismiss any loading toasts
      toast.dismiss();

      // Toast error message
      if (
        typeof errorData?.statusCode === "number" &&
        errorData.message.length > 0
      ) {
        toast.error(errorData.statusCode + " - " + errorData.message);
      }

      if (
        typeof errorData?.detail === "string" &&
        errorData.detail.length > 0
      ) {
        toast.error(errorData.detail);
      }

      console.error(error.response);
      return Promise.reject(error.response);
    },
    [getToken]
  );

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      responseHandler,
      errorHandler
    );

    return () => axios.interceptors.response.eject(interceptor);
  }, [errorHandler, responseHandler]);

  useEffect(() => {
    if (!accessToken) return;
    // @ts-ignore
    const interceptor = axios.interceptors.request.use(requestHandler);

    return () => axios.interceptors.request.eject(interceptor);
  }, [requestHandler, accessToken]);

  if (!isTokenLoaded) {
    return <LoadingSpinner text="Loading..." />;
  }

  return <>{children}</>;
};
