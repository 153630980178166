export const PERMISSIONS = {
  DELETE_BIKE: "delete:bike",
  DELETE_REPORT: "delete:report",
  DELETE_KEY: "delete:key",
  UPDATE_KEY: "update:key",
  READ_LOGS: "read:logs",
  READ_SUBMISSIONS: "read:submissions",
  READ_USERS: "read:users",
  UPDATE_BIKE: "update:bike",
} as const;

export const ROLES = {
  ADMIN: "admin",
  SWAPPER: "swapper",
} as const;
