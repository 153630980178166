import clsx from "clsx";
import EmptyTableState from "./EmptyTableState";
import Pagination from "./Pagination";
import TableActions from "./TableActions";
import { TableProps } from "./TableProps";
import TableSkeleton from "./TableSkeleton";

function Table<T>(props: TableProps<T>) {
  if (props.isLoading) {
    return <TableSkeleton columns={props.columns} />;
  }

  if (props.data.length === 0) {
    return (
      <EmptyTableState
        emptyMessage={props.emptyMessage || "No data available"}
        columns={props.columns}
      />
    );
  }

  return (
    <div className="relative overflow-x-auto rounded-t-lg mt-2">
      <table className="w-full text-sm text-gray-500">
        <thead className="text-xs text-left text-gray-900 uppercase">
          <tr>
            {props.columns.map((column, index) => (
              <th
                key={index}
                className={clsx(
                  column.classNameHeader,
                  "border-b border-gray-200 py-4"
                )}
              >
                {column.label}
              </th>
            ))}
            {props.tableActions && (
              <th className="text-gray-900 border-b border-gray-200 py-4"></th>
            )}
          </tr>
        </thead>
        <tbody>
          {props.data &&
            props.data.map((item, index) => (
              <tr key={index} className="text-left text-xs sm:text-sm">
                {props.columns.map((column, colIndex) => {
                  const value = column.accessor(item);
                  return (
                    <td
                      key={colIndex}
                      className={clsx(
                        column.classNameCell,
                        "py-7 sm:py-5 text-gray-900 border-b border-gray-200"
                      )}
                    >
                      {column.render
                        ? column.render(value, item)
                        : value != null
                        ? String(value)
                        : ""}
                    </td>
                  );
                })}

                {props.tableActions && (
                  <td className="w-2 md:w-32 text-right text-gray-900 border-b border-gray-200 py-4">
                    <TableActions
                      item={item}
                      deleteMessageFn={props.tableActions.deleteMessageFn}
                      deleteFn={props.tableActions.deleteFn}
                      idAccessor={props.idAccessor}
                      editPermission={props.editPermission}
                      deletePermission={props.deletePermission}
                      hideViewButton={props.hideViewButton}
                      shouldShowEdit={props.tableActions.shouldShowEdit}
                    />
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
      <Pagination
        totalItems={props.totalCount || 0}
        itemsPerPage={props.pageSize || 10}
        currentPage={props.page || 1}
        onPageChange={(pageNumber) =>
          props.onPageChange && props.onPageChange(pageNumber)
        }
      />
    </div>
  );
}

export default Table;
