import { useState } from "react";
import toast from "react-hot-toast";
import { useCreateItem } from "../../../modules/api/hooks/useCreateItem";
import ReportForm from "../../../modules/forms/ReportForm";
import { Breadcrumbs } from "../../../modules/layout/Breadcrumbs";
import { ReportPriority } from "../../../modules/api/interfaces/report";

export interface IReportForm {
  id?: string;
  journalNumber: string;
  date: string;
  isActive: boolean;
  priority: ReportPriority;
  description: string;
  bikeFrameNumber: string;
  updatedBy: string;
  bikeId?: string;
}

export function NewReportPage() {
  const createReportMutation = useCreateItem<IReportForm>("/reports", {
    onError: (error) => {
      setError(error);
    },
  });

  const [error, setError] = useState("");

  const handleSubmit = async (data: IReportForm) => {
    toast.promise(createReportMutation.mutateAsync(data), {
      loading: "Creating report...",
      success: "Report created successfully!",
      error: "Error creating report.",
    });
  };

  return (
    <>
      <div className="py-12 px-4 max-w-7xl mx-auto">
        <Breadcrumbs />
        <div className="flex items-center justify-between">
          <p className="text-2xl tracking-wide font-medium h-10">
            Create new report
          </p>
        </div>
        <ReportForm
          buttonPlaceholder="Create report"
          onSubmit={(data) => handleSubmit(data)}
        />
        {error && (
          <div className="text-red-500 mt-4 whitespace-pre-wrap">{error}</div>
        )}
      </div>
    </>
  );
}
