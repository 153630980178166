import Pagination from "./Pagination";
import { Column } from "./TableProps";

function EmptyTableState<T>(props: {
  columns: Column<T>[];
  emptyMessage: string;
}) {
  return (
    <div className="relative overflow-x-auto rounded-t-lg mt-2">
      <table className="w-full text-sm text-gray-500">
        <thead className="text-xs text-left text-gray-900 uppercase">
          <tr>
            {props.columns.map((column, index) => (
              <th
                key={index}
                className={`${column.classNameHeader} border-b border-gray-200 py-4`}
              >
                {column.label}
              </th>
            ))}
            <th className="text-gray-900 border-b border-gray-200 py-4"></th>
          </tr>
        </thead>
        <tbody>
          {new Array(1).fill(0).map((_, i) => (
            <tr key={i}>
              <td
                colSpan={6}
                className="py-4 items-center text-center text-gray-900 border-b border-gray-200"
              >
                <div>{props.emptyMessage}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        totalItems={0}
        itemsPerPage={0}
        currentPage={1}
        onPageChange={() => {}}
      />
    </div>
  );
}

export default EmptyTableState;
