import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import Button from "../components/Button";
import FormSelect from "../components/form/FormSelect";
import FormInput from "../components/form/FormInput";
import FormMultiSelect from "../components/form/FormMultiSelect";
import { IKey, KeyType } from "../api/interfaces/key";
import FormDate from "../components/form/FormDate";

interface KeyFormProps {
  keyData?: IKey;
  readOnly?: boolean;
  editMode?: boolean;
  buttonPlaceholder?: string;
  allKeys: IKey[];
  onSubmit?: (selectedKeys: string[]) => void;
}

const KeyForm: React.FC<KeyFormProps> = ({
  keyData,
  readOnly = false,
  editMode = false,
  buttonPlaceholder = "Submit",
  allKeys,
  onSubmit = () => {},
}) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm<IKey>({
    defaultValues: keyData || {
      id: "",
      frameNumber: "",
      keyType: KeyType.Both,
      requestedAt: "",
      selectedKeys: [],
    },
  });

  const keyTypeOptions = Object.entries(KeyType)
    .filter(([key]) => isNaN(Number(key)))
    .map(([key, value]) => ({ label: key, value }));

  const unrequestedKeys = allKeys.filter((key) => !key.requestedAt);

  const selectedKeys = watch("selectedKeys") || [];
  return (
    <form>
      {!editMode ? (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6 mt-4">
          <Fragment>
            <FormInput
              type="text"
              name="id"
              control={control}
              label="ID"
              disabled={readOnly}
              placeholder="ID"
            />

            <FormInput
              type="text"
              name="frameNumber"
              control={control}
              label="Frame number"
              disabled={readOnly}
              placeholder="Frame number"
            />

            <FormSelect
              name="keyType"
              control={control}
              label="What keys are needed?"
              options={keyTypeOptions}
              disabled={readOnly}
            />

            {control._formValues.requestedAt !== null ? (
              <FormDate
                name="requestedAt"
                type="date"
                control={control}
                label="Requested at"
                disabled={readOnly}
              />
            ) : null}
          </Fragment>
        </div>
      ) : (
        <Fragment>
          <div className="grid grid-cols-1 gap-4 sm:gap-6 mt-4">
            <FormMultiSelect
              name="selectedKeys"
              control={control}
              disabled={isSubmitting}
              label="Select keys to request"
              options={unrequestedKeys}
            />
          </div>

          <Button
            onClick={handleSubmit((data) => onSubmit(data.selectedKeys || []))}
            title={buttonPlaceholder}
            variant="primary"
            isLoading={isSubmitting}
            disabled={selectedKeys.length <= 0 || isSubmitting}
            className="mt-4 w-full"
          />
        </Fragment>
      )}
    </form>
  );
};

export default KeyForm;
