import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  FilterConfig,
  FilterValue,
} from "../../components/search/SearchComponent";
import { useSessionStorage } from "./useSessionStorage";

export const useSearchHandler = (
  initialFilters: FilterConfig[],
  storageKey: string,
  searchKey: string
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [savedSearch, setSavedSearch] = useSessionStorage(storageKey, {});
  const navigate = useNavigate();

  useEffect(() => {
    const hasParams = Array.from(searchParams.keys()).length > 0;
    if (!hasParams && Object.keys(savedSearch).length > 0) {
      const restoredParams = new URLSearchParams(savedSearch);
      navigate(`?${restoredParams.toString()}`, { replace: true });
    }
  }, [searchParams, savedSearch, navigate]);

  const handleSearch = (
    query: string,
    filters: Record<string, FilterValue>
  ) => {
    const newParams: Record<string, string> = {};

    if (query) newParams[searchKey] = query;

    Object.entries(filters).forEach(([key, filterValue]) => {
      const value = filterValue?.value;
      if (
        value !== null &&
        value !== undefined &&
        value !== "" &&
        typeof value !== "object"
      ) {
        newParams[key] = String(value);
      }
    });

    newParams.page = "1";
    newParams.pageSize = "10";

    setSavedSearch(newParams);
    setSearchParams(newParams);
  };

  const handlePageChange = (newPage: number) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set("page", newPage.toString());
    newParams.set("pageSize", "10");

    const updated = Object.fromEntries(newParams.entries());
    setSavedSearch(updated);
    setSearchParams(newParams);
  };

  const initialFiltersFromParams: Record<string, FilterValue> = {};
  initialFilters.forEach((filter) => {
    const value = searchParams.get(filter.key);
    if (value !== null) {
      const label =
        filter.options?.find((opt: any) => opt.value.toString() === value)
          ?.label || value;
      const parsedValue = filter.type === "checkbox" ? value === "true" : value;
      initialFiltersFromParams[filter.key] = { value: parsedValue, label };
    }
  });

  return {
    handleSearch,
    handlePageChange,
    initialFiltersFromParams,
    searchParams,
  };
};
