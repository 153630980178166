import { useErrorBoundary } from "react-error-boundary";
import Button from "../modules/components/Button";

export default function ErrorPage({ error }: { error: Error }) {
  const { resetBoundary } = useErrorBoundary();
  return (
    <div className="flex flex-col items-center justify-center min-h-screen gap-2">
      <h1 className="text-4xl font-bold">Something went wrong:</h1>
      <pre className="text-red-500 text-xs italic justify-center">
        {error.message}
      </pre>
      <Button variant="primary" title="Try again" onClick={resetBoundary} />
    </div>
  );
}
