import { Fragment } from "react";
import Table from "../../../modules/components/table/Table";
import MainLayout from "../../../modules/components/MainLayout";
import { IKey, KeyType } from "../../../modules/api/interfaces/key";
import { add, format } from "date-fns";
import { PERMISSIONS } from "../../../modules/auth/auth";
import { useDeleteItem } from "../../../modules/api/hooks/useDeleteItem";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import Button from "../../../modules/components/Button";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import SearchComponent, {
  FilterConfig,
} from "../../../modules/components/search/SearchComponent";
import { useSearchHandler } from "../../../modules/api/hooks/useSearchHandler";
import useKeys from "../../../modules/api/hooks/useKeys";

const keysFilters: FilterConfig[] = [
  {
    key: "keyType",
    label: "Keys needed",
    type: "select",
    options: [
      { label: "Frame", value: "1" },
      { label: "Battery", value: "2" },
      { label: "Both", value: "3" },
    ],
  },
  {
    key: "dateFrom",
    label: "Date from",
    type: "date",
  },
  {
    key: "dateTo",
    label: "Date to",
    type: "date",
  },
  {
    key: "isRequested",
    label: "Requested",
    type: "select",
    options: [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
  },
];

const columns = [
  {
    label: "Keys needed",
    accessor: (row: IKey) => KeyType[row.keyType],
    render: (type: KeyType) => (
      <span className="px-2 w-16 text-center rounded inline-block bg-gray-200">
        {type}
      </span>
    ),
    classNameCell: "text-center sm:text-left",
    classNameHeader: "text-center sm:text-left",
  },
  {
    label: "Frame Number",
    accessor: (row: IKey) => row.frameNumber,
    classNameCell: "text-center sm:text-left",
    classNameHeader: "text-center sm:text-left",
  },

  {
    label: "Requested at",
    accessor: (row: IKey) => row.requestedAt,
    render: (date: string) => {
      if (date === null) return "Not requested";
      return format(add(new Date(date), { hours: 1 }), "dd-MM-yyyy");
    },
    classNameCell: "text-center sm:text-left",
    classNameHeader: "text-center sm:text-left",
  },
];

export function KeysPage() {
  const {
    handleSearch,
    handlePageChange,
    initialFiltersFromParams,
    searchParams,
  } = useSearchHandler(keysFilters, "keysSearchParams", "frameNumber");
  const { data: keyRequests, isLoading } = useKeys(searchParams.toString());
  const { getPermission } = useKindeAuth();
  const { mutate: deleteKeyRequest } = useDeleteItem("/keys", {
    onSuccess: () => {
      toast.success("Key request deleted successfully!");
    },
    onError: (error: { response: { data: string } }) => {
      toast.error(error.response.data);
    },
  });

  const handleDelete = (key: IKey) => {
    deleteKeyRequest({ id: key.id || "" });
  };

  return (
    <MainLayout
      title="Key Requests"
      buttons={
        getPermission && getPermission(PERMISSIONS.UPDATE_KEY)?.isGranted ? (
          <Fragment>
            <Link to="./request">
              <Button
                title="Request keys"
                variant="primary"
                className="w-full sm:w-auto"
              />
            </Link>
          </Fragment>
        ) : null
      }
      searchHeader={
        <SearchComponent
          placeholder="Search frame number..."
          searchType="number"
          initialFilters={keysFilters}
          appliedFilters={initialFiltersFromParams}
          onSearch={handleSearch}
        />
      }
      body={
        <Fragment>
          <Table
            data={keyRequests?.data || []}
            columns={columns}
            tableActions={{
              deleteFn: (item) => handleDelete(item),
              deleteMessageFn: (item) =>
                `Are you sure you want to delete key request for '${
                  KeyType[item.keyType]
                }' key(s) for bike - ${item.frameNumber}?`,
            }}
            page={keyRequests?.page || 1}
            pageSize={keyRequests?.pageSize || 10}
            totalCount={keyRequests?.totalCount || 0}
            onPageChange={(page) => handlePageChange(page)}
            idAccessor={(row) => row.id}
            isLoading={isLoading}
            emptyMessage="No key requests found"
            deletePermission={PERMISSIONS.DELETE_KEY}
          />
        </Fragment>
      }
    />
  );
}
