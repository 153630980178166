import React, { useState, useEffect, useCallback } from "react";
import Button from "../Button";
import clsx from "clsx";
import { XCircleIcon } from "@heroicons/react/24/solid";

interface FilterOption {
  label: string;
  value: string | number;
}

export interface FilterConfig {
  key: string;
  label: string;
  type: "text" | "number" | "select" | "date" | "checkbox";
  options?: FilterOption[]; // For select dropdowns
  placeholder?: string;
}

export interface FilterValue {
  value: string | number | boolean;
  label?: string;
}

interface SearchComponentProps {
  placeholder?: string;
  searchType?: "text" | "number" | "date";
  initialFilters: FilterConfig[];
  appliedFilters?: Record<string, FilterValue>;
  onSearch: (query: string, filters: Record<string, FilterValue>) => void;
  className?: string;
}

const inputClasses =
  "disabled:opacity-70 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline outline-2 focus:outline-customBlue block p-2.5 sm:min-w-32 w-full min-h-[42px]";

const SearchComponent: React.FC<SearchComponentProps> = ({
  placeholder = "Search...",
  searchType = "text",
  initialFilters,
  appliedFilters = {},
  onSearch,
  className,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState<Record<string, FilterValue>>({});

  useEffect(() => {
    const mergedFilters: Record<string, FilterValue> = {};

    initialFilters.forEach((filter) => {
      const init = appliedFilters[filter.key];

      if (init !== undefined) {
        mergedFilters[filter.key] = init;
      } else {
        switch (filter.type) {
          case "select":
            mergedFilters[filter.key] = { value: "", label: "" };
            break;
          case "checkbox":
            mergedFilters[filter.key] = { value: false };
            break;
          default:
            mergedFilters[filter.key] = { value: "" };
        }
      }
    });
    setFilters(mergedFilters);
  }, [initialFilters, appliedFilters]);

  const handleFilterChange = useCallback((key: string, value: FilterValue) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  }, []);

  const handleSearch = () => {
    onSearch(searchTerm, filters);
  };

  const resetFilters = () => {
    const reset: Record<string, FilterValue> = {};
    initialFilters.forEach((filter) => {
      switch (filter.type) {
        case "select":
          reset[filter.key] = { value: "", label: "" };
          break;
        case "checkbox":
          reset[filter.key] = { value: false };
          break;
        default:
          reset[filter.key] = { value: "" };
      }
    });
    setFilters(reset);
    setSearchTerm("");
    onSearch("", reset);
  };

  const renderFilterInput = (filter: FilterConfig) => {
    const currentValue = filters[filter.key]?.value ?? "";

    switch (filter.type) {
      case "select":
        return (
          <select
            id={filter.key}
            value={currentValue.toString()}
            onChange={(e) => {
              const selected = filter.options?.find(
                (opt) => opt.value.toString() === e.target.value
              );
              handleFilterChange(filter.key, {
                value: e.target.value,
                label: selected?.label ?? e.target.value,
              });
            }}
            className={inputClasses}
          >
            <option value="">{filter.label}</option>
            {filter.options?.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </select>
        );

      case "number":
        return (
          <input
            id={filter.key}
            type="number"
            value={currentValue.toString()}
            onChange={(e) =>
              handleFilterChange(filter.key, {
                value: e.target.value ? Number(e.target.value) : "",
                label: e.target.value,
              })
            }
            placeholder={filter.placeholder}
            className={inputClasses}
          />
        );

      case "checkbox":
        return (
          <label
            id={filter.key}
            className="flex items-center gap-1 text-sm whitespace-nowrap"
          >
            <input
              id={filter.key}
              type="checkbox"
              checked={!!currentValue}
              onChange={(e) =>
                handleFilterChange(filter.key, {
                  value: e.target.checked,
                  label: e.target.checked ? "Yes" : "No",
                })
              }
              className="h-4 w-4"
            />
            {filter.label}
          </label>
        );

      case "date":
        return (
          <input
            id={filter.key}
            type="date"
            value={currentValue.toString()}
            onChange={(e) =>
              handleFilterChange(filter.key, {
                value: e.target.value,
                label: e.target.value,
              })
            }
            className={clsx(inputClasses, "text-center")}
          />
        );

      default:
        return (
          <input
            id={filter.key}
            type="text"
            value={currentValue.toString()}
            onChange={(e) =>
              handleFilterChange(filter.key, {
                value: e.target.value,
                label: e.target.value,
              })
            }
            placeholder={filter.placeholder}
            className={inputClasses}
          />
        );
    }
  };

  return (
    <div className={clsx("flex flex-col lg:flex-row gap-4 w-full", className)}>
      <div className="relative flex-grow">
        <input
          id="search"
          type={searchType}
          value={searchTerm}
          placeholder={placeholder}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="disabled:opacity-70 bg-gray-50 border border-gray-300 text-xs text-gray-900 rounded-lg focus:outline outline-2 focus:outline-customBlue block w-full p-2.5 pr-10"
        />
        {(searchTerm || Object.values(filters).some((f) => f?.value)) && (
          <div className="absolute right-3 top-1/2 -translate-y-1/2 group">
            <XCircleIcon
              className="w-5 h-5 text-gray-400 cursor-pointer hover:text-gray-600"
              onClick={resetFilters}
            />
            <span className="absolute bottom-full left-1/2 -translate-x-1/2 mb-1 px-2 py-1 text-xs text-gray-100 bg-gray-600 rounded opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none z-10 whitespace-nowrap">
              Clear all filters
            </span>
          </div>
        )}
      </div>

      {initialFilters.map((filter) => (
        <div key={filter.key}>{renderFilterInput(filter)}</div>
      ))}

      <Button
        title="Search"
        variant="secondary"
        onClick={handleSearch}
        className="whitespace-nowrap"
      />
    </div>
  );
};

export default SearchComponent;
