import { Navigate, useNavigate, useParams } from "react-router-dom";
import { add, format } from "date-fns";
import { IReport } from "../../../modules/api/interfaces/report";
import Table from "../../../modules/components/table/Table";
import { Breadcrumbs } from "../../../modules/layout/Breadcrumbs";
import BikeForm from "../../../modules/forms/BikeForm";
import { IBike, isEBike } from "../../../modules/api/interfaces/bike";
import LoadingSpinner from "../../../modules/components/LoadingSpinner";
import Button from "../../../modules/components/Button";
import { PencilIcon } from "@heroicons/react/24/solid";
import useFetchData from "../../../modules/api/hooks/useFetchData";

export function ShowBikePage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: bike, isLoading } = useFetchData<IBike>(`/bikes/${id}`);

  if (isLoading) return <LoadingSpinner text="Loading bike..." />;

  if (!bike) {
    return <Navigate to="/dashboard/bikes" />;
  }

  const columns = [
    {
      label: "Journal number",
      accessor: (row: IReport) => ({
        id: row.id,
        journalNumber: row.journalNumber,
      }),
      render: (value: { id: string; journalNumber: string }, row: IReport) => {
        return (
          <button
            className="underline hover:text-customBlue cursor-pointer"
            onClick={() => {
              navigate("/dashboard/reports/" + value.id);
            }}
          >
            {value.journalNumber}
          </button>
        );
      },
    },
    {
      label: "Date",
      accessor: (row: IReport) => row.date,
      render: (date: Date) => {
        return format(add(new Date(date), { hours: 1 }), "dd-MM-yyyy");
      },
    },
    {
      label: "Description",
      accessor: (row: IReport) => row.description,
      render: (description: string) => {
        return description.slice(0, 80) + "...";
      },
      classNameHeader: "hidden sm:table-cell",
      classNameCell: "hidden sm:table-cell",
    },
    {
      label: "Frame number",
      accessor: (row: IReport) => "SF" + row.bikeFrameNumber,
      classNameHeader: "hidden sm:table-cell",
      classNameCell: "hidden sm:table-cell",
    },
    {
      label: "Active?",
      accessor: (row: IReport) => row.isActive === true,
      render: (active: boolean) => (
        <span
          className={`px-2 py-1 rounded-full text-white ${
            active ? "bg-green-500" : "bg-red-500"
          }`}
        >
          {active ? "Yes" : "No"}
        </span>
      ),
    },
  ];

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <Breadcrumbs />
      <div className="flex items-center justify-between">
        <p className="text-2xl tracking-wide font-medium">Bike</p>
        {isEBike(bike.bikeType) && (
          <Button
            variant="icon"
            icon={PencilIcon}
            onClick={() => navigate("/dashboard/bikes/edit/" + id)}
          />
        )}
      </div>
      <BikeForm readOnly bikeData={bike} />
      <h2 className="block mt-8 mb-2 text-gray-900">Bikes report history</h2>
      <Table
        data={bike?.reports || []}
        columns={columns}
        tableActions={{
          deleteFn: (item) => console.log(item),
          deleteMessageFn: (item) =>
            `Are you sure you want to delete ${item.journalNumber}?`,
        }}
        idAccessor={(row) => row.id}
        emptyMessage="No reports found for this bike"
        hideViewButton={true}
      />
    </div>
  );
}
