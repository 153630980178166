import { useNavigate } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useEffect } from "react";
import { ILog } from "../../../modules/api/interfaces/log";
import Table from "../../../modules/components/table/Table";
import { format } from "date-fns";
import MainLayout from "../../../modules/components/MainLayout";
import { PERMISSIONS } from "../../../modules/auth/auth";
import SearchComponent, {
  FilterConfig,
} from "../../../modules/components/search/SearchComponent";
import useLogs from "../../../modules/api/hooks/useLogs";
import { useSearchHandler } from "../../../modules/api/hooks/useSearchHandler";

const logsFilters: FilterConfig[] = [
  {
    key: "method",
    label: "Method",
    type: "select",
    options: [
      { label: "GET", value: "GET" },
      { label: "POST", value: "POST" },
      { label: "PUT", value: "PUT" },
      { label: "DELETE", value: "DELETE" },
    ],
  },
  {
    key: "statusCode",
    label: "Status code",
    type: "select",
    options: [
      { label: "500", value: 500 },
      { label: "400", value: 400 },
      { label: "404", value: 404 },
    ],
  },
  {
    key: "dateFrom",
    label: "Date from",
    type: "date",
  },
  {
    key: "dateTo",
    label: "Date to",
    type: "date",
  },
];

const columns = [
  {
    label: "Time",
    accessor: (row: ILog) => row.timestamp,
    render: (date: Date) => {
      return format(new Date(date), "dd-MM-yyyy HH:mm");
    },
  },
  {
    label: "Method",
    accessor: (row: ILog) => row.method,
    classNameCell: "text-center sm:text-left px-4 lg:px-0",
    classNameHeader: "text-center sm:text-left px-4 lg:px-0",
  },
  {
    label: "Endpoint",
    accessor: (row: ILog) => row.url,
    render: (url: string) => <span>{url.split("/api")[1]}</span>,
    classNameHeader: "hidden sm:table-cell",
    classNameCell: "hidden sm:table-cell",
  },

  {
    label: "Status code",
    accessor: (row: ILog) => row.statusCode,
    render: (statusCode: number) => {
      let bgColor = "bg-gray-500";

      if (statusCode >= 200 && statusCode < 300) {
        bgColor = "bg-green-500";
      } else if (statusCode >= 300 && statusCode < 400) {
        bgColor = "bg-blue-500";
      } else if (statusCode >= 400 && statusCode < 500) {
        bgColor = "bg-orange-500";
      } else if (statusCode >= 500) {
        bgColor = "bg-red-500";
      }

      return (
        <span className={`px-2 py-1 rounded-full text-white ${bgColor}`}>
          {statusCode}
        </span>
      );
    },
    classNameCell: "text-center sm:text-left",
    classNameHeader: "text-center sm:text-left",
  },
  {
    label: "User",
    accessor: (row: ILog) => row.userName,
    classNameCell: "text-center sm:text-left",
    classNameHeader: "text-center sm:text-left",
  },
];

export function LogsPage() {
  const {
    handleSearch,
    handlePageChange,
    initialFiltersFromParams,
    searchParams,
  } = useSearchHandler(logsFilters, "logsSearchParams", "search");
  const { data: logs, isLoading } = useLogs(searchParams.toString());
  const navigate = useNavigate();
  const { getPermission } = useKindeAuth();

  useEffect(() => {
    if (!(getPermission && getPermission(PERMISSIONS.READ_LOGS)?.isGranted)) {
      navigate("/dashboard/statistics");
    }
  }, [getPermission, navigate]);

  return (
    <MainLayout
      title="Logs"
      searchHeader={
        <SearchComponent
          placeholder="Search..."
          searchType="text"
          initialFilters={logsFilters}
          appliedFilters={initialFiltersFromParams}
          onSearch={handleSearch}
        />
      }
      body={
        <Table
          data={logs?.data || []}
          columns={columns}
          tableActions={{
            deleteFn: (item) => console.log("Deleting", item),
            deleteMessageFn: (item) =>
              `Are you sure you want to delete ${item.requestId}?`,
          }}
          page={logs?.page || 1}
          pageSize={logs?.pageSize || 10}
          totalCount={logs?.totalCount || 0}
          onPageChange={(page) => handlePageChange(page)}
          idAccessor={(row) => row.id}
          isLoading={isLoading}
          emptyMessage="No logs found"
        />
      }
    />
  );
}
