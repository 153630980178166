import { IEntity } from "./entity";

export interface IKey extends IEntity {
  keyType: KeyType;
  frameNumber: string;
  requestedAt?: string;
  selectedKeys?: string[];
}

export enum KeyType {
  Frame = 1,
  Battery = 2,
  Both = 3,
}
