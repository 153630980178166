import clsx from "clsx";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

interface IInputFieldProps {
  label: string;
  name: string;
  type: string;
  value: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  readonly?: boolean;
  className?: string;
  link?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputField: FC<IInputFieldProps> = ({
  label,
  name,
  type,
  placeholder,
  value,
  disabled,
  required,
  readonly,
  className,
  link,
  onChange,
}) => {
  const navigate = useNavigate();
  return (
    <div>
      <label
        htmlFor={name}
        className="block mb-2 text-sm font-medium text-gray-900"
      >
        {label}
      </label>
      {link ? (
        <input
          onClick={() => navigate(link)}
          className={clsx(
            className,
            "disabled:opacity-70 bg-gray-50 border border-gray-300 text-gray-900 underline hover:text-customBlue cursor-pointer text-sm rounded-lg focus:ring-customBlue focus:border-customBlue block w-full p-2.5"
          )}
          value={value}
        />
      ) : (
        <input
          type={type}
          name={name}
          id={name}
          className={clsx(
            className,
            "disabled:opacity-70 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-customBlue focus:border-customBlue block w-full p-2.5"
          )}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={onChange}
          required={required}
          readOnly={readonly}
        />
      )}
    </div>
  );
};

export default InputField;
