import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useToken } from "./misc/useToken";
import { IUpdateKeyRequest } from "../../../pages/dashboard";

interface IUseUpdateBikeProps {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

export const useUpdateKeys = (props?: IUseUpdateBikeProps) => {
  const queryClient = useQueryClient();
  const accessToken = useToken();

  const updateKeys = async (keys: IUpdateKeyRequest[]) => {
    if (!accessToken) {
      throw new Error("No access token available");
    }

    return await axios.put(
      process.env.REACT_APP_API_URL + "/keys",
      keys.map((key) => ({
        id: key.id,
        requestedDate: key.requestedAt,
      })),
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  return useMutation({
    mutationKey: ["update", "keys"],
    mutationFn: (keys: IUpdateKeyRequest[]) => updateKeys(keys),
    onError: (error: Error) => {
      console.error(error);
      props?.onError?.(error);
    },
    onSuccess: async (data, key) => {
      await queryClient.invalidateQueries({
        queryKey: ["keys"],
      });
      key.forEach(async (key) => {
        await queryClient.invalidateQueries({
          queryKey: [`/keys/${key.id}`],
        });
      });
      props?.onSuccess?.();
    },
  });
};
