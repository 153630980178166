import { IEntity } from "./entity";

export interface IReport extends IEntity {
  journalNumber: string;
  date: string;
  description: string;
  isActive: boolean;
  priority: ReportPriority;
  bikeFrameNumber: string;
  updatedBy: string;
}

export enum ReportPriority {
  //Select = -1,
  None = 0,
  Low = 1,
  Medium = 2,
  High = 3,
}
