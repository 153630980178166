import { Link } from "react-router-dom";
import { add, format } from "date-fns";
import toast from "react-hot-toast";
import { useDeleteItem } from "../../../modules/api/hooks/useDeleteItem";
import {
  IReport,
  ReportPriority,
} from "../../../modules/api/interfaces/report";
import Button from "../../../modules/components/Button";
import SearchComponent, {
  FilterConfig,
} from "../../../modules/components/search/SearchComponent";
import Table from "../../../modules/components/table/Table";
import MainLayout from "../../../modules/components/MainLayout";
import { Fragment } from "react/jsx-runtime";
import { PERMISSIONS } from "../../../modules/auth/auth";
import useReports from "../../../modules/api/hooks/reports/useReports";
import { useSearchHandler } from "../../../modules/api/hooks/useSearchHandler";

const reportFilters: FilterConfig[] = [
  {
    key: "priority",
    label: "Priority",
    type: "select",
    options: [
      { label: "None", value: "0" },
      { label: "Low", value: "1" },
      { label: "Medium", value: "2" },
      { label: "High", value: "3" },
    ],
  },
  {
    key: "dateFrom",
    label: "Date from",
    type: "date",
  },
  {
    key: "dateTo",
    label: "Date to",
    type: "date",
  },
  {
    key: "active",
    label: "Active",
    type: "select",
    options: [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
  },
];

const columns = [
  {
    label: "Journal number",
    accessor: (row: IReport) => row.journalNumber,
    classNameHeader: "hidden xl:table-cell",
    classNameCell: "hidden xl:table-cell",
  },
  {
    label: "Date",
    accessor: (row: IReport) => row.date,
    render: (date: Date) => {
      return format(add(new Date(date), { hours: 1 }), "dd-MM-yyyy");
    },
  },
  {
    label: "Description",
    accessor: (row: IReport) => row.description,
    render: (description: string) => {
      return description.slice(0, 45) + "...";
    },
    classNameHeader: "hidden lg:table-cell",
    classNameCell: "hidden lg:table-cell",
  },
  {
    label: "Frame number",
    accessor: (row: IReport) => "SF" + row.bikeFrameNumber,
  },
  {
    label: "Priority",
    accessor: (row: IReport) => row.priority,
    render: (priority: ReportPriority) => {
      return (
        <span
          className={`px-2 py-1 rounded-full text-white ${
            priority === ReportPriority.None ? "bg-gray-500" : "bg-customBlue"
          }`}
        >
          {ReportPriority[priority]}
        </span>
      );
    },
    classNameCell: "text-center sm:text-left w-24",
    classNameHeader: "text-center sm:text-left",
  },
  {
    label: "Active",
    accessor: (row: IReport) => row.isActive === true,
    render: (active: boolean) => (
      <span
        className={`px-2 py-1 rounded-full text-white ${
          active ? "bg-green-500" : "bg-red-500"
        }`}
      >
        {active ? "Yes" : "No"}
      </span>
    ),
    classNameCell: "text-center sm:text-left",
    classNameHeader: "text-center sm:text-left",
  },
];

export function ReportsPage() {
  const {
    handleSearch,
    handlePageChange,
    initialFiltersFromParams,
    searchParams,
  } = useSearchHandler(reportFilters, "reportsSearchParams", "journalNumber");
  const { data: reports, isLoading } = useReports(searchParams.toString());

  const { mutate: deleteReport } = useDeleteItem("/reports", {
    onSuccess: () => {
      toast.success("Report deleted successfully!");
    },
    onError: () => {
      toast.error("Could not delete report. Please try again.");
    },
  });

  const handleDelete = (report: IReport) => {
    deleteReport({ id: report.id || "" });
  };

  return (
    <MainLayout
      title="Reports"
      buttons={
        <Fragment>
          <Link to="./new">
            <Button
              title="Create report"
              variant="primary"
              className="w-full sm:w-auto"
            />
          </Link>
        </Fragment>
      }
      searchHeader={
        <SearchComponent
          placeholder="Search journal number..."
          initialFilters={reportFilters}
          appliedFilters={initialFiltersFromParams}
          onSearch={handleSearch}
        />
      }
      body={
        <Fragment>
          <Table
            data={reports?.data || []}
            columns={columns}
            isLoading={isLoading}
            tableActions={{
              deleteFn: (item) => handleDelete(item),
              deleteMessageFn: (item) =>
                `Are you sure you want to delete report with journal number - ${item.journalNumber}?`,
              shouldShowEdit: (item: IReport) => true,
            }}
            page={reports?.page || 1}
            pageSize={reports?.pageSize || 10}
            totalCount={reports?.totalCount || 0}
            onPageChange={(page) => handlePageChange(page)}
            idAccessor={(row) => row.id}
            emptyMessage="No reports found"
            deletePermission={PERMISSIONS.DELETE_REPORT}
          />
        </Fragment>
      }
    />
  );
}
