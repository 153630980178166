import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import useFetchData from "../../../modules/api/hooks/useFetchData";
import { ISubmission } from "../../../modules/api/interfaces/submission";
import LoadingSpinner from "../../../modules/components/LoadingSpinner";
import SubmissionForm from "../../../modules/forms/SubmissionForm";
import { Breadcrumbs } from "../../../modules/layout/Breadcrumbs";
import { useEffect } from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { PERMISSIONS } from "../../../modules/auth/auth";

export function ShowSubmissionPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getPermission } = useKindeAuth();
  const { data: submission, isLoading } = useFetchData<ISubmission>(
    `/submissions/${id}`
  );

  useEffect(() => {
    if (
      !(getPermission && getPermission(PERMISSIONS.READ_SUBMISSIONS)?.isGranted)
    ) {
      navigate("/dashboard/statistics");
    }
  }, [getPermission, navigate]);

  if (isLoading) return <LoadingSpinner text="Loading submission..." />;

  if (!submission) {
    return <Navigate to="/dashboard/submissions" />;
  }

  return (
    <Fragment>
      <div className="py-12 px-4 max-w-7xl mx-auto">
        <Breadcrumbs />
        <div className="flex items-center justify-between">
          <p className="text-2xl tracking-wide font-medium">Submission</p>
        </div>
        <SubmissionForm readOnly submissionData={submission} />
      </div>
    </Fragment>
  );
}
