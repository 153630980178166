import { Navigate, useParams } from "react-router-dom";
import useFetchData from "../../../modules/api/hooks/useFetchData";
import LoadingSpinner from "../../../modules/components/LoadingSpinner";
import { Breadcrumbs } from "../../../modules/layout/Breadcrumbs";
import { IKey } from "../../../modules/api/interfaces/key";
import KeyForm from "../../../modules/forms/KeyForm";

export function ShowKeyPage() {
  const { id } = useParams();
  const { data: keyRequest, isLoading } = useFetchData<IKey>(`/keys/${id}`);

  if (isLoading) return <LoadingSpinner text="Loading key request..." />;

  if (!keyRequest) {
    return <Navigate to="/dashboard/keys" />;
  }

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <Breadcrumbs />
      <div className="flex items-center justify-between">
        <p className="text-2xl tracking-wide font-medium">Key request</p>
      </div>
      <KeyForm keyData={keyRequest} readOnly allKeys={[]} />
    </div>
  );
}
