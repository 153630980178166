import { Navigate, useNavigate, useParams } from "react-router-dom";
import { add, format } from "date-fns";
import { PencilIcon } from "@heroicons/react/24/solid";
import useFetchData from "../../../modules/api/hooks/useFetchData";
import { IReport } from "../../../modules/api/interfaces/report";
import Button from "../../../modules/components/Button";
import LoadingSpinner from "../../../modules/components/LoadingSpinner";
import ReportForm from "../../../modules/forms/ReportForm";
import { Breadcrumbs } from "../../../modules/layout/Breadcrumbs";

export function ShowReportPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: report, isLoading } = useFetchData<IReport>(`/reports/${id}`);

  if (isLoading) return <LoadingSpinner text="Loading report..." />;

  if (!report) {
    return <Navigate to="/dashboard/reports" />;
  }

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <Breadcrumbs />
      <div className="flex items-center justify-between">
        <p className="text-2xl tracking-wide font-medium">Report</p>
        <Button
          variant="icon"
          icon={PencilIcon}
          onClick={() => navigate("/dashboard/reports/edit/" + report?.id)}
        />
      </div>
      <ReportForm reportData={report} readOnly />
      <div className="text-gray-400 text-xs mt-1">
        Last updated on{" "}
        {format(
          add(new Date(report?.modified), { hours: 1 }),
          "MMM dd, yyyy 'at' HH:mm"
        )}
        {" by "}
        <p className="inline-flex text-xs">{report?.updatedBy || "Unknown"}</p>
      </div>
    </div>
  );
}
