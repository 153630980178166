import clsx from "clsx";
import React from "react";
import { useController, Control } from "react-hook-form";
import { format } from "date-fns"; // Import date-fns functions

interface FormDateProps {
  name: string;
  type: string;
  control: Control<any>;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  handleClick?: () => void;
  dateFormat?: string;
}

const FormDate: React.FC<FormDateProps> = ({
  name,
  type = "text",
  control,
  label,
  disabled = false,
  placeholder,
  className,
  handleClick,
  dateFormat = "yyyy-MM-dd",
}) => {
  const {
    field: { onChange, value, ...fieldProps },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  // Format the displayed date according to the specified format
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dateValue = e.target.value;
    onChange(dateValue ? dateValue : null);
  };

  // Format value for display if it exists
  const formattedValue = value
    ? typeof value === "string"
      ? value.includes("T")
        ? format(new Date(value), dateFormat)
        : value
      : format(new Date(value), dateFormat)
    : "";

  return (
    <div>
      {label && (
        <label
          htmlFor={name}
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          {label}
        </label>
      )}
      <input
        {...fieldProps}
        id={name}
        type="date"
        value={formattedValue}
        onChange={handleDateChange}
        readOnly={type !== "date" && disabled}
        disabled={type === "date" && disabled}
        placeholder={placeholder}
        autoComplete="off"
        onClick={handleClick}
        className={clsx(
          className,
          "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline outline-2 focus:outline-customBlue block w-full p-2.5",
          error && "border-red-500 border-2"
        )}
      />
      {error && <p className="text-red-500 text-xs mt-1">{error.message}</p>}
    </div>
  );
};

export default FormDate;
